// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.coupon-component-div {
    font-size: 13px;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
}

.coupon-component-div-form {
    display: grid;
    grid-template-columns: 90% 5% 5%;
}

.coupon-component-div-form input {
    border: 1px solid #dfe0e6;
    outline: none !important;
    color: #494949;
    height: 2rem;
    line-height: 18px;
    padding: 8px 16px;
    box-shadow: none;
    border-radius: 2px;
    border-right: 0px;
}
.coupon-component-div-form button {
    cursor: pointer;
    border: 0px;
    background-color: var(--Logo-Color1);
    color: #fff;
}
.coupon-clear-button
{
    background-color: var(--Discount-Color) !important;
    margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/Admin/Coupon/Coupon.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;IACb,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,gCAAgC;AACpC;;AAEA;IACI,yBAAyB;IACzB,wBAAwB;IACxB,cAAc;IACd,YAAY;IACZ,iBAAiB;IACjB,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;AACrB;AACA;IACI,eAAe;IACf,WAAW;IACX,oCAAoC;IACpC,WAAW;AACf;AACA;;IAEI,kDAAkD;IAClD,iBAAiB;AACrB","sourcesContent":[".coupon-component-div {\r\n    font-size: 13px;\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 0.2rem;\r\n}\r\n\r\n.coupon-component-div-form {\r\n    display: grid;\r\n    grid-template-columns: 90% 5% 5%;\r\n}\r\n\r\n.coupon-component-div-form input {\r\n    border: 1px solid #dfe0e6;\r\n    outline: none !important;\r\n    color: #494949;\r\n    height: 2rem;\r\n    line-height: 18px;\r\n    padding: 8px 16px;\r\n    box-shadow: none;\r\n    border-radius: 2px;\r\n    border-right: 0px;\r\n}\r\n.coupon-component-div-form button {\r\n    cursor: pointer;\r\n    border: 0px;\r\n    background-color: var(--Logo-Color1);\r\n    color: #fff;\r\n}\r\n.coupon-clear-button\r\n{\r\n    background-color: var(--Discount-Color) !important;\r\n    margin-left: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
