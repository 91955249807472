import React from "react";
import { Container } from "@mantine/core";
import Breadcrumb from "../../../components/UI/Breadcrumb/Breadcrumb";
import "./ShippingPolicy.css";
const ShippingPolicy = () => {
  // array for breadcrumb
  const items = [
    { title: "Home", href: "/" },
    { title: "Shipping Policy", href: "/shipping_policy" },
  ];

  return (
    <div>
      <div className="terms-and-conditions-div">
        <Container
          className="terms-and-conditions-div-container"
          size={"82rem"}
        >
          <div className="category-div-container-breadcrumb">
            <Breadcrumb value={items} />
          </div>
          <div className="terms-and-conditions-div-container-heading">
            <h1>Shipping</h1>
          </div>
          <div className="terms-and-conditions-div-container-details">
            <h1>What are the delivery charges?</h1>
            <p>Delivery charge varies with each Seller.</p>
            <p>
              Sellers incur relatively higher shipping costs on low value items.
              In such cases, charging a nominal delivery charge helps them
              offset logistics costs. Please check your order summary to
              understand the delivery charges for individual products.
            </p>
            <p>
              For Products listed as TheMogo Plus, a Rs 40 charge for delivery
              per item may be applied if the order value is less than Rs 500.
              While, orders of Rs 500 or above are delivered free.
            </p>
          </div>
          <div className="terms-and-conditions-div-container-details">
            <h1>
              Why does the delivery date not correspond to the delivery timeline
              of X-Y business days?
            </h1>
            <p>
              It is possible that the Seller or our courier partners have a
              holiday between the day your placed your order and the date of
              delivery, which is based on the timelines shown on the product
              page. In this case, we add a day to the estimated date. Some
              courier partners and Sellers do not work on Sundays and this is
              factored in to the delivery dates.
            </p>
          </div>
          <div className="terms-and-conditions-div-container-details">
            <h1>What is the estimated delivery time?</h1>
            <p>
              Sellers generally procure and ship the items within the time
              specified on the product page. Business days exclude public
              holidays and Sundays.
            </p>
            <p>Estimated delivery time depends on the following factors:</p>
            <ul>
              <li>The Seller offering the product</li>
              <li>Product's availability with the Seller</li>
              <li>
                The destination to which you want the order shipped to and
                location of the Seller.
              </li>
            </ul>
          </div>
          <div className="terms-and-conditions-div-container-details">
            <h1>
              Are there any hidden costs (sales tax, octroi etc) on items sold
              by Sellers on TheMogo?
            </h1>
            <p>
              There are NO hidden charges when you make a purchase on TheMogo.
              List prices are final and all-inclusive. The price you see on the
              product page is exactly what you would pay.
            </p>
            <p>
              Delivery charges are not hidden charges and are charged (if at
              all) extra depending on the Seller's shipping policy.
            </p>
          </div>
          <div className="terms-and-conditions-div-container-details">
            <h1>Why does the estimated delivery time vary for each seller?</h1>
            <p>
              You have probably noticed varying estimated delivery times for
              sellers of the product you are interested in. Delivery times are
              influenced by product availability, geographic location of the
              Seller, your shipping destination and the courier partner's
              time-to-deliver in your location.
            </p>
            <p>
              Please enter your default pin code on the product page (you don't
              have to enter it every single time) to know more accurate delivery
              times on the product page itself.
            </p>
          </div>
          <div className="terms-and-conditions-div-container-details">
            <h1>Seller does not/cannot ship to my area. Why?</h1>
            <p>
              Please enter your pincode on the product page (you don't have to
              enter it every single time) to know whether the product can be
              delivered to your location.
            </p>
            <p>
              If you haven't provided your pincode until the checkout stage, the
              pincode in your shipping address will be used to check for
              serviceability.
            </p>
            <p>Whether your location can be serviced or not depends on</p>
            <ul>
              <li>Whether the Seller ships to your location</li>
              <li>
                Legal restrictions, if any, in shipping particular products to
                your location
              </li>
              <li>
                The availability of reliable courier partners in your location
              </li>
            </ul>
            <p>
              At times Sellers prefer not to ship to certain locations. This is
              entirely at their discretion.
            </p>
          </div>
          <div className="terms-and-conditions-div-container-details">
            <h1>Why is the CoD option not offered in my location?</h1>
            <p>
              Availability of CoD depends on the ability of our courier partner
              servicing your location to accept cash as payment at the time of
              delivery.
            </p>
            <p>
              Our courier partners have limits on the cash amount payable on
              delivery depending on the destination and your order value might
              have exceeded this limit. Please enter your pin code on the
              product page to check if CoD is available in your location.
            </p>
          </div>
          <div className="terms-and-conditions-div-container-details">
            <h1>I need to return an item, how do I arrange for a pick-up?</h1>
            <p>
              Returns are easy. Contact Us to initiate a return. You will
              receive a call explaining the process, once you have initiated a
              return.
            </p>
            <p>
              Wherever possible Ekart Logistics will facilitate the pick-up of
              the item. In case, the pick-up cannot be arranged through Ekart,
              you can return the item through a third-party courier service.
              Return fees are borne by the Seller.
            </p>
          </div>
          <div className="terms-and-conditions-div-container-details">
            <h1>
              I did not receive my order but got a delivery confirmation
              SMS/Email.
            </h1>
            <p>
              In case the product was not delivered and you received a delivery
              confirmation email/SMS, report the issue within 7 days from the
              date of delivery confirmation for the seller to investigate.
            </p>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default ShippingPolicy;
