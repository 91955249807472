//import react pakages
import React, { useEffect, useState } from "react";

//import mantine packages
import {
  Button,
  Checkbox,
  Container,
  Flex,
  Image,
  Indicator,
  Input,
  Modal,
  Paper,
  PasswordInput,
  Select,
} from "@mantine/core";

//import react router dom packages
import { Link, useLocation } from "react-router-dom";

//import Header css
import "./Header.css";

//import images
import logo from "../../../assets/BrandLogo/brnad_logo.png";
import arrowdown from "../../../assets/preheader/arrow-down.webp";
import search from "../../../assets/header/search.webp";
import cart from "../../../assets/header/cart.webp";
import wishlist from "../../../assets/header/wishlist.webp";
import { Heart, ShoppingCart } from "tabler-icons-react";
import { useMediaQuery } from "@mantine/hooks";
import { useDispatch, useSelector } from "react-redux";
import { setWishList } from "../../../StateHandler/Slice/wishList/WishListSlice";
import { setCart } from "../../../StateHandler/Slice/CartSlice/CartSlice";
import { createCartAPI } from "../../../config/quries/cart/CartQuries";
import axios from "axios";

import config from "../../../config/server/Servers";

//select category array in search
const searchCategoryArray = [
  { value: "all", label: "All Categories" },
  { value: "table_linen", label: "Table Linen" },
  { value: "kitchen_linen", label: "Kitchen Linen" },
  { value: "bath_linen", label: "Bath Linen" },
  { value: "bed_linen", label: "Bed Linen" },
  { value: "living_linen", label: "Living Linen" },
  { value: "baby_linen", label: "Baby Linen" },
];

const Header = () => {
  // Loaction

  const location = useLocation();
  //usestate for open register modal
  const [registermodalOpen, setRegisterModalOpen] = useState(false);
  //usestate for open login modal
  const [loginmodalOpen, setLoginModalOpen] = useState(false);
  const [wishListLength, setWishListLength] = useState(0);
  const [cartLength, setCartLength] = useState(0);
  const dispatch = useDispatch();

  //usestate for open login modal
  const [forgotmodalOpen, setForgotModalOpen] = useState(false);

  // usestate for search product
  const [searchText, setSearchText] = useState("");

  const header1060MediaQuery = useMediaQuery("(max-width:1061px)");
  const header786MediaQuery = useMediaQuery("(max-width:787px)");

  const cartValue = JSON.parse(sessionStorage.getItem("cartProducts"));
  const wishListValue = JSON.parse(sessionStorage.getItem("wishList"));
  const wishListData = useSelector((state) => state.wishListData.value);
  const cartListData = useSelector((state) => state.cartData.value);

  useEffect(() => {
    if (wishListData) {
      setWishListLength(wishListData.length);
    }
  }, [wishListData]);
  useEffect(() => {
    dispatch(setWishList(wishListValue));
  }, []);

  useEffect(() => {
    if (cartListData) {
      setCartLength(cartListData.length);
    } else {
      setCartLength(0);
    }
  }, [cartListData]);

  useEffect(() => {
    dispatch(setCart(cartValue));
  }, []);

  useEffect(() => {
    if (location.pathname !== "/orderplaced") {
      sessionStorage.removeItem("orderID");
    }
  }, [location.pathname]);

  const [selectedValue, setSelectedValue] = useState({
    value: searchCategoryArray[0].value,
    label: searchCategoryArray[0].label,
  });

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const handleParagraphClick = (value, label) => {
    setSelectedValue({ value, label });
    setIsDropdownVisible(false);
  };

  const handleSearchInput = (event) => {
    console.log(event);
    console.log("Search Onchange");
  };

  return (
    <div>
      <div className="header-div">
        <Flex
          pt={"0.3rem"}
          pb={"1rem"}
          pl={"2rem"}
          pr={"2rem"}
          align={"center"}
          justify={"space-around"}
        >
          <Flex align={"center"} gap={header1060MediaQuery ? "0.5rem" : "2rem"}>
            <Image
              component={Link}
              to="/"
              width={header1060MediaQuery ? "130px" : "160px"}
              src={logo}
              alt="logo"
            />
            <div className="header-div-container-search">
              <Paper className="header-div-container-search-paper">
                <div className="search-by-category-container">
                  <Input
                    className="search-by-category-input"
                    readOnly
                    value={selectedValue.label}
                    onMouseEnter={() => setIsDropdownVisible(true)}
                  />
                  {isDropdownVisible && (
                    <div className="header-div-container-search-paper-dropdown">
                      {searchCategoryArray.map((searchArr, index) => {
                        return (
                          <p
                            onClick={() =>
                              handleParagraphClick(
                                searchArr.value,
                                searchArr.label
                              )
                            }
                            key={index}
                          >
                            {searchArr.label}
                          </p>
                        );
                      })}
                    </div>
                  )}
                </div>
                <Input
                  className="header-div-container-search-input"
                  placeholder="Search Products"
                  value={searchText}
                  onChange={handleSearchInput}
                />
                <Button className="header-div-container-search-button">
                  <img src={search} width="20px" alt="search-icon" />
                </Button>
              </Paper>
            </div>
          </Flex>
          <Flex
            gap={header786MediaQuery ? "0.3rem" : "1.5rem"}
            className="search-engine-right"
            align={"center"}
          >
            <Flex
              component={Link}
              to="/cart"
              className="navbar-link"
              gap={3}
              align={"center"}
            >
              {cartLength > 0 ? (
                <Indicator
                  inline
                  label={cartLength > 0 ? cartLength : ""}
                  size={16}
                >
                  <Flex align={"center"}>
                    <ShoppingCart
                      strokeWidth={1}
                      size={header786MediaQuery ? "1.2rem" : "2rem"}
                    />
                    <p>Cart</p>
                  </Flex>
                </Indicator>
              ) : (
                <Flex align={"center"}>
                  <ShoppingCart
                    strokeWidth={1}
                    size={header786MediaQuery ? "1.2rem" : "2rem"}
                  />
                  <p>Cart</p>
                </Flex>
              )}
            </Flex>
            <Flex
              component={Link}
              to="/wishlist"
              className="navbar-link"
              gap={3}
              align={"center"}
            >
              {wishListLength ? (
                <Indicator
                  inline
                  label={wishListLength > 0 ? wishListLength : ""}
                  size={16}
                >
                  <Flex align={"center"}>
                    <Heart
                      strokeWidth={1}
                      size={header786MediaQuery ? "1.2rem" : "2rem"}
                    />
                    <p>Wishlist</p>
                  </Flex>
                </Indicator>
              ) : (
                <Flex align={"center"}>
                  <Heart
                    strokeWidth={1}
                    size={header786MediaQuery ? "1.2rem" : "2rem"}
                  />
                  <p>Wishlist</p>
                </Flex>
              )}
            </Flex>
            <Button
              className="header-div-container-cart-wishlist-sell-now-sellnow-button"
              // onClick={() => setLoginModalOpen(true)}
              component={Link}
              to="/vendor_registration"
            >
              Sell Now
            </Button>
          </Flex>
        </Flex>
        <Modal
          zIndex={12121}
          size="md"
          opened={registermodalOpen}
          onClose={() => setRegisterModalOpen(false)}
          title=""
          transitionProps={{
            transition: "fade",
            duration: 350,
            timingFunction: "linear",
          }}
          className="preheader-register-modal"
        >
          <div className="preheader-register-modal-header">
            <h1>Register</h1>
          </div>
          <div className="preheader-register-modal-body">
            <div className="preheader-register-modal-body-content">
              <Input placeholder="First Name" />
              <Input placeholder="Last Name" />
              <Input placeholder="Email Address" />
              <PasswordInput
                placeholder="Password"
                className="preheader-register-model-password-show-hide-btn"
              />
              <PasswordInput
                placeholder="Confirm Password"
                className="preheader-register-model-password-show-hide-btn"
              />
              <Checkbox
                className="preheader-register-model-checkbox"
                label={
                  <>
                    I have read and agree to the{" "}
                    <Link
                      onClick={() => setRegisterModalOpen(false)}
                      to="/terms-and-conditions"
                      className="preheader-register-model-tearms-and-conditions-link"
                    >
                      Terms & Conditions
                    </Link>
                  </>
                }
              />
              <button
              // onClick={handleRegister}
              >
                Register
              </button>
              <p className="preheader-register-model-goto-login">
                Have an account?{" "}
                <span
                  onClick={() => {
                    setRegisterModalOpen(false);
                    setLoginModalOpen(true);
                  }}
                >
                  Login
                </span>
              </p>
            </div>
          </div>
        </Modal>
        {/*Register modal end */}
        {/* Login model starts */}
        <Modal
          zIndex={12121212}
          size="md"
          opened={loginmodalOpen}
          onClose={() => setLoginModalOpen(false)}
          title=""
          centered
          transitionProps={{
            transition: "fade",
            duration: 350,
            timingFunction: "linear",
          }}
          className="preheader-login-modal"
        >
          <div className="preheader-login-modal-header">
            <h1>Login</h1>
          </div>
          <div className="preheader-login-modal-body">
            <div className="preheader-login-modal-body-content">
              <Input placeholder="Email Address" />
              <PasswordInput
                placeholder="Password"
                className="preheader-login-model-password-show-hide-btn"
              />
              <p
                className="preheader-login-model-goto-forgot-password"
                onClick={() => {
                  setForgotModalOpen(true);
                  setLoginModalOpen(false);
                }}
              >
                Forgot Password?
              </p>
              <button>Login</button>
              <p className="preheader-login-model-goto-login">
                Don't have an account?{" "}
                <span
                  onClick={() => {
                    setRegisterModalOpen(true);
                    setLoginModalOpen(false);
                  }}
                >
                  Register
                </span>
              </p>
            </div>
          </div>
        </Modal>
        {/*Login modal end */}
        {/* Forgot model starts */}
        <Modal
          zIndex={12121}
          size="md"
          opened={forgotmodalOpen}
          onClose={() => setForgotModalOpen(false)}
          title=""
          transitionProps={{
            transition: "fade",
            duration: 350,
            timingFunction: "linear",
          }}
          className="preheader-login-modal"
        >
          <div className="preheader-login-modal-header">
            <h1>Reset Password</h1>
          </div>
          <div className="preheader-login-modal-body">
            <div className="preheader-login-modal-body-content">
              <p className="preheader-forgot-model-sub-heading">
                Enter your email address
              </p>
              <Input placeholder="Email Address" />
              <Input placeholder="OTP In Email" />
              <button>Submit</button>
            </div>
          </div>
        </Modal>
        {/*Forgot modal end */}
      </div>
    </div>
  );
};

export default Header;
