// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payout-requests-div {
    font-family: 'Source Sans Pro', sans-serif;
}

.payout-requests-div .mantine-othpma {
    padding: 0px;
}

.payout-requests-div-card-heading {
    padding: 20px 20px 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.payout-requests-div-card-heading h3 {
    font-size: 18px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
}

.payout-requests-div-card-heading button {
    width: 114px;
    height: 34px;
    cursor: pointer;
    border: 0px;
    background-color: var(--Logo-Color1);
    color: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 3px;
}

.payout-requests-div-card-body {
    padding: 20px;
}

.payoutrequests-filter-container {
    display: grid;
    grid-template-columns: 9% 10% 25% 25%;
    gap: 1rem;
    font-family: 'Source Sans Pro', sans-serif;
    align-items: baseline;
}

.payoutrequests-filter-container-div3 .mantine-1fzet7j {
    display: block !important;
}

.payoutrequests-filter-container-div3 .mantine-ydg8wd {
    margin: 0px;
    display: flex;
    flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/pages/Admin/Payouts/PayoutRequests/PayoutRequests.css"],"names":[],"mappings":"AAAA;IACI,0CAA0C;AAC9C;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,sBAAsB;IACtB,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,0CAA0C;IAC1C,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,WAAW;IACX,oCAAoC;IACpC,WAAW;IACX,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,qCAAqC;IACrC,SAAS;IACT,0CAA0C;IAC1C,qBAAqB;AACzB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;AAC1B","sourcesContent":[".payout-requests-div {\r\n    font-family: 'Source Sans Pro', sans-serif;\r\n}\r\n\r\n.payout-requests-div .mantine-othpma {\r\n    padding: 0px;\r\n}\r\n\r\n.payout-requests-div-card-heading {\r\n    padding: 20px 20px 5px;\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n}\r\n\r\n.payout-requests-div-card-heading h3 {\r\n    font-size: 18px;\r\n    font-family: 'Source Sans Pro', sans-serif;\r\n    font-weight: 400;\r\n}\r\n\r\n.payout-requests-div-card-heading button {\r\n    width: 114px;\r\n    height: 34px;\r\n    cursor: pointer;\r\n    border: 0px;\r\n    background-color: var(--Logo-Color1);\r\n    color: #fff;\r\n    display: flex;\r\n    justify-content: space-around;\r\n    align-items: center;\r\n    border-radius: 3px;\r\n}\r\n\r\n.payout-requests-div-card-body {\r\n    padding: 20px;\r\n}\r\n\r\n.payoutrequests-filter-container {\r\n    display: grid;\r\n    grid-template-columns: 9% 10% 25% 25%;\r\n    gap: 1rem;\r\n    font-family: 'Source Sans Pro', sans-serif;\r\n    align-items: baseline;\r\n}\r\n\r\n.payoutrequests-filter-container-div3 .mantine-1fzet7j {\r\n    display: block !important;\r\n}\r\n\r\n.payoutrequests-filter-container-div3 .mantine-ydg8wd {\r\n    margin: 0px;\r\n    display: flex;\r\n    flex-direction: column;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
