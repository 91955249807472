// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quote-div {
    width: 100%;
    padding: 30px 0px;
    font-family: 'Open Sans';
}

.quote-div-container {
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.quote-div-container-content-table {
    width: 100%;
}

.quote-div-container-content-table tr th {
    padding: 15px;
    text-align: left;
    border-bottom: 2px solid var(--Para-Color);
}

.quote-div-container-content-table tr td {
    padding: 15px;
    border-bottom: 2px solid var(--Extra-Light-Gray);
}

.options-quote-div {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.options-quote-div .quote-accept {
    padding: 5px;
    cursor: pointer;
    border: 0px;
    background-color: var(--Logo-Color1);
    color: white;
}

.options-quote-div .quote-reject {
    padding: 5px;
    cursor: pointer;
    border: 0px;
    background-color: var(--Para-Color);
    color: white;
}

.options-quote-div .quote-delete {
    padding: 5px;
    cursor: pointer;
    border: 0px;
    background-color: var(--Discount-Color);
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/pages/UI/Quote/Quote.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;IACjB,wBAAwB;AAC5B;;AAEA;IACI,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,0CAA0C;AAC9C;;AAEA;IACI,aAAa;IACb,gDAAgD;AACpD;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,WAAW;IACX,oCAAoC;IACpC,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,WAAW;IACX,mCAAmC;IACnC,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,WAAW;IACX,uCAAuC;IACvC,YAAY;AAChB","sourcesContent":[".quote-div {\r\n    width: 100%;\r\n    padding: 30px 0px;\r\n    font-family: 'Open Sans';\r\n}\r\n\r\n.quote-div-container {\r\n    padding: 0;\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 1rem;\r\n}\r\n\r\n.quote-div-container-content-table {\r\n    width: 100%;\r\n}\r\n\r\n.quote-div-container-content-table tr th {\r\n    padding: 15px;\r\n    text-align: left;\r\n    border-bottom: 2px solid var(--Para-Color);\r\n}\r\n\r\n.quote-div-container-content-table tr td {\r\n    padding: 15px;\r\n    border-bottom: 2px solid var(--Extra-Light-Gray);\r\n}\r\n\r\n.options-quote-div {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 0.5rem;\r\n}\r\n\r\n.options-quote-div .quote-accept {\r\n    padding: 5px;\r\n    cursor: pointer;\r\n    border: 0px;\r\n    background-color: var(--Logo-Color1);\r\n    color: white;\r\n}\r\n\r\n.options-quote-div .quote-reject {\r\n    padding: 5px;\r\n    cursor: pointer;\r\n    border: 0px;\r\n    background-color: var(--Para-Color);\r\n    color: white;\r\n}\r\n\r\n.options-quote-div .quote-delete {\r\n    padding: 5px;\r\n    cursor: pointer;\r\n    border: 0px;\r\n    background-color: var(--Discount-Color);\r\n    color: white;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
