// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.activesales-div
{
    font-family: 'Source Sans Pro', sans-serif;
}
.activesales-div-card
{
    padding: 0px;
}
.activesales-div-card-heading
{
    padding: 20px 20px 5px;
}
.activesales-div-card-content
{
    padding: 20px;
}
.sales-container
{
    grid-template-columns: 8% 14% 20% 20%;
}
.sales-button
{
    cursor: pointer;
    width: 80px;
    height: 32px;
    border: 0px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.payoutrequests-filter-container-div5 .mantine-ydg8wd {
    margin: 0px;
    display: flex;
    flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/pages/Vendor/Sales/ActiveSales/ActiveSales.css"],"names":[],"mappings":"AAAA;;IAEI,0CAA0C;AAC9C;AACA;;IAEI,YAAY;AAChB;AACA;;IAEI,sBAAsB;AAC1B;AACA;;IAEI,aAAa;AACjB;AACA;;IAEI,qCAAqC;AACzC;AACA;;IAEI,eAAe;IACf,WAAW;IACX,YAAY;IACZ,WAAW;IACX,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;AACvB;AACA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;AAC1B","sourcesContent":[".activesales-div\r\n{\r\n    font-family: 'Source Sans Pro', sans-serif;\r\n}\r\n.activesales-div-card\r\n{\r\n    padding: 0px;\r\n}\r\n.activesales-div-card-heading\r\n{\r\n    padding: 20px 20px 5px;\r\n}\r\n.activesales-div-card-content\r\n{\r\n    padding: 20px;\r\n}\r\n.sales-container\r\n{\r\n    grid-template-columns: 8% 14% 20% 20%;\r\n}\r\n.sales-button\r\n{\r\n    cursor: pointer;\r\n    width: 80px;\r\n    height: 32px;\r\n    border: 0px;\r\n    display: flex;\r\n    justify-content: space-evenly;\r\n    align-items: center;\r\n}\r\n.payoutrequests-filter-container-div5 .mantine-ydg8wd {\r\n    margin: 0px;\r\n    display: flex;\r\n    flex-direction: column;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
