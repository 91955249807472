// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blogs-div {
    font-family: 'Source Sans Pro', sans-serif;
}

.blogs-div-card {
    padding: 0px;
}

.blogs-div-card-heading {
    padding: 20px 20px 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.blogs-div-card-heading h4 {
    font-weight: 400;
}

.blogs-div-card-heading button {
    width: 99px;
    height: 34px;
    cursor: pointer;
    border: 0px;
    background-color: var(--Logo-Color1);
    border-radius: 3px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: #fff;
}

.blogs-div-card-body {
    padding: 20px;
}

.padding0 .mantine-kcpopt
{
    padding: 0px 10px;
    height: 2rem;
}
.ht-20
{
    height: 100px;
    display: flex;
    align-items: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/pages/Admin/Blogs/Blogs.css"],"names":[],"mappings":"AAAA;IACI,0CAA0C;AAC9C;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,sBAAsB;IACtB,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;IACf,WAAW;IACX,oCAAoC;IACpC,kBAAkB;IAClB,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,aAAa;AACjB;;AAEA;;IAEI,iBAAiB;IACjB,YAAY;AAChB;AACA;;IAEI,aAAa;IACb,aAAa;IACb,qBAAqB;AACzB","sourcesContent":[".blogs-div {\r\n    font-family: 'Source Sans Pro', sans-serif;\r\n}\r\n\r\n.blogs-div-card {\r\n    padding: 0px;\r\n}\r\n\r\n.blogs-div-card-heading {\r\n    padding: 20px 20px 5px;\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n}\r\n\r\n.blogs-div-card-heading h4 {\r\n    font-weight: 400;\r\n}\r\n\r\n.blogs-div-card-heading button {\r\n    width: 99px;\r\n    height: 34px;\r\n    cursor: pointer;\r\n    border: 0px;\r\n    background-color: var(--Logo-Color1);\r\n    border-radius: 3px;\r\n    display: flex;\r\n    justify-content: space-evenly;\r\n    align-items: center;\r\n    color: #fff;\r\n}\r\n\r\n.blogs-div-card-body {\r\n    padding: 20px;\r\n}\r\n\r\n.padding0 .mantine-kcpopt\r\n{\r\n    padding: 0px 10px;\r\n    height: 2rem;\r\n}\r\n.ht-20\r\n{\r\n    height: 100px;\r\n    display: flex;\r\n    align-items: flex-end;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
