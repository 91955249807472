// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.terms-and-conditions-div {
    width: 100%;
    padding: 30px 0px;
    font-family: 'Open Sans';
}

.terms-and-conditions-div-container {
    padding: 0;
}

.terms-and-conditions-div-container-heading h1 {
    font-size: 26px;
    margin: 30px 0px;
}

.terms-and-conditions-div-container-heading p {
    font-size: 15px;
    margin-bottom: 16px;
}

.terms-and-conditions-div-container-details h1 {
    font-size: 15px;
}

.terms-and-conditions-div-container-details p {
    font-size: 15px;
    margin-bottom: 16px;
}`, "",{"version":3,"sources":["webpack://./src/pages/UI/TermsAndConditions/TermsAndConditions.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;IACjB,wBAAwB;AAC5B;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB","sourcesContent":[".terms-and-conditions-div {\r\n    width: 100%;\r\n    padding: 30px 0px;\r\n    font-family: 'Open Sans';\r\n}\r\n\r\n.terms-and-conditions-div-container {\r\n    padding: 0;\r\n}\r\n\r\n.terms-and-conditions-div-container-heading h1 {\r\n    font-size: 26px;\r\n    margin: 30px 0px;\r\n}\r\n\r\n.terms-and-conditions-div-container-heading p {\r\n    font-size: 15px;\r\n    margin-bottom: 16px;\r\n}\r\n\r\n.terms-and-conditions-div-container-details h1 {\r\n    font-size: 15px;\r\n}\r\n\r\n.terms-and-conditions-div-container-details p {\r\n    font-size: 15px;\r\n    margin-bottom: 16px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
