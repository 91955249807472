// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reviews-div {
    font-family: 'Source Sans Pro', sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/pages/Vendor/Reviews/Reviews.css"],"names":[],"mappings":"AAAA;IACI,0CAA0C;AAC9C","sourcesContent":[".reviews-div {\r\n    font-family: 'Source Sans Pro', sans-serif;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
