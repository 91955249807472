// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mantine-eby8v3 {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
}

.products-div {
  font-family: "Source Sans Pro", sans-serif;
}

.products-filter-container {
  display: grid;
  grid-template-columns: 9% 10% 10% 10% auto;
  gap: 1rem;
  font-family: "Source Sans Pro", sans-serif;
}

.products-filter-container-div4 {
  width: 100%;
}

.products-filter-container-div4 .mantine-ydg8wd {
  margin: 0px;
  max-width: 250px;
}

.products-filter-container-div4 .mantine-1fzet7j,
.products-filter-container-div5 .mantine-1fzet7j,
.products-filter-container-div3 .mantine-1fzet7j,
.products-filter-container-div2 .mantine-1fzet7j,
.products-filter-container-div1 .mantine-1fzet7j {
  font-family: "Source Sans Pro", sans-serif;
  margin-bottom: 5px;
  font-weight: 600 !important;
}

.custum-table-style {
  text-align: center;
}

.products-filter-container-div2 input {
  z-index: 12345;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Admin/Product/Products/Products.css"],"names":[],"mappings":"AAAA;EACE,0CAA0C;EAC1C,eAAe;AACjB;;AAEA;EACE,0CAA0C;AAC5C;;AAEA;EACE,aAAa;EACb,0CAA0C;EAC1C,SAAS;EACT,0CAA0C;AAC5C;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;;;;;EAKE,0CAA0C;EAC1C,kBAAkB;EAClB,2BAA2B;AAC7B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".mantine-eby8v3 {\r\n  font-family: \"Source Sans Pro\", sans-serif;\r\n  font-size: 14px;\r\n}\r\n\r\n.products-div {\r\n  font-family: \"Source Sans Pro\", sans-serif;\r\n}\r\n\r\n.products-filter-container {\r\n  display: grid;\r\n  grid-template-columns: 9% 10% 10% 10% auto;\r\n  gap: 1rem;\r\n  font-family: \"Source Sans Pro\", sans-serif;\r\n}\r\n\r\n.products-filter-container-div4 {\r\n  width: 100%;\r\n}\r\n\r\n.products-filter-container-div4 .mantine-ydg8wd {\r\n  margin: 0px;\r\n  max-width: 250px;\r\n}\r\n\r\n.products-filter-container-div4 .mantine-1fzet7j,\r\n.products-filter-container-div5 .mantine-1fzet7j,\r\n.products-filter-container-div3 .mantine-1fzet7j,\r\n.products-filter-container-div2 .mantine-1fzet7j,\r\n.products-filter-container-div1 .mantine-1fzet7j {\r\n  font-family: \"Source Sans Pro\", sans-serif;\r\n  margin-bottom: 5px;\r\n  font-weight: 600 !important;\r\n}\r\n\r\n.custum-table-style {\r\n  text-align: center;\r\n}\r\n\r\n.products-filter-container-div2 input {\r\n  z-index: 12345;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
