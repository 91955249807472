// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.coupons-div {
    font-family: 'Source Sans Pro', sans-serif;
}

.coupons-div-card {
    padding: 0px;
}

.coupons-div-card-heading {
    padding: 20px 20px 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.coupons-div-card-heading button{
width: 130px;
height: 36px;
cursor: pointer;
display: flex;
justify-content: space-evenly;
align-items: center;
border: 0px;
color: #fff;
background-color: var(--Logo-Color1);
border-radius: 3px;
}
.coupons-div-card-content
{
    padding: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Vendor/Coupons/Coupons.css"],"names":[],"mappings":"AAAA;IACI,0CAA0C;AAC9C;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,sBAAsB;IACtB,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;AACA;AACA,YAAY;AACZ,YAAY;AACZ,eAAe;AACf,aAAa;AACb,6BAA6B;AAC7B,mBAAmB;AACnB,WAAW;AACX,WAAW;AACX,oCAAoC;AACpC,kBAAkB;AAClB;AACA;;IAEI,aAAa;AACjB","sourcesContent":[".coupons-div {\r\n    font-family: 'Source Sans Pro', sans-serif;\r\n}\r\n\r\n.coupons-div-card {\r\n    padding: 0px;\r\n}\r\n\r\n.coupons-div-card-heading {\r\n    padding: 20px 20px 5px;\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n}\r\n.coupons-div-card-heading button{\r\nwidth: 130px;\r\nheight: 36px;\r\ncursor: pointer;\r\ndisplay: flex;\r\njustify-content: space-evenly;\r\nalign-items: center;\r\nborder: 0px;\r\ncolor: #fff;\r\nbackground-color: var(--Logo-Color1);\r\nborder-radius: 3px;\r\n}\r\n.coupons-div-card-content\r\n{\r\n    padding: 20px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
