// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.orderplaced-div {
    width: 100%;
    font-family: 'Open Sans';
    margin-bottom: 100px;
    text-align: center;
}

.orderplaced-div-container {
    padding: 0;
}

.orderplaced-div-container-main {
    padding-top: 120px;
    width: 450px;
    margin: auto;
}

.orderplaced-div-container-main-icon {
    margin-bottom: 2rem;
}

.orderplaced-div-container-main-details h1 {
    font-size: 18px;
    margin-bottom: 5px;
    font-weight: 500;
}

.orderplaced-div-container-main-details .order-number-display {
    font-weight: bold;
    font-size: 18px;
}

.orderplaced-div-container-main-goback {
    margin-top: 2rem;
    text-align: center;
}

.orderplaced-div-container-main-goback button {
    border: 0;
    background-color: var(--Logo-Color1);
    color: #fff;
    width: 200px;
    height: 45px;
    cursor: pointer;
    border-radius: 2px;
}

.orderplaced-div-container-main-goback button:hover {
    background-color: var(--Logo-Color2);
}`, "",{"version":3,"sources":["webpack://./src/pages/UI/OrderPlaced/OrderPlaced.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,wBAAwB;IACxB,oBAAoB;IACpB,kBAAkB;AACtB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,SAAS;IACT,oCAAoC;IACpC,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,oCAAoC;AACxC","sourcesContent":[".orderplaced-div {\r\n    width: 100%;\r\n    font-family: 'Open Sans';\r\n    margin-bottom: 100px;\r\n    text-align: center;\r\n}\r\n\r\n.orderplaced-div-container {\r\n    padding: 0;\r\n}\r\n\r\n.orderplaced-div-container-main {\r\n    padding-top: 120px;\r\n    width: 450px;\r\n    margin: auto;\r\n}\r\n\r\n.orderplaced-div-container-main-icon {\r\n    margin-bottom: 2rem;\r\n}\r\n\r\n.orderplaced-div-container-main-details h1 {\r\n    font-size: 18px;\r\n    margin-bottom: 5px;\r\n    font-weight: 500;\r\n}\r\n\r\n.orderplaced-div-container-main-details .order-number-display {\r\n    font-weight: bold;\r\n    font-size: 18px;\r\n}\r\n\r\n.orderplaced-div-container-main-goback {\r\n    margin-top: 2rem;\r\n    text-align: center;\r\n}\r\n\r\n.orderplaced-div-container-main-goback button {\r\n    border: 0;\r\n    background-color: var(--Logo-Color1);\r\n    color: #fff;\r\n    width: 200px;\r\n    height: 45px;\r\n    cursor: pointer;\r\n    border-radius: 2px;\r\n}\r\n\r\n.orderplaced-div-container-main-goback button:hover {\r\n    background-color: var(--Logo-Color2);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
