// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blogs-div {
    font-family: 'Open Sans';
    padding: 20px 0px;
}
.blogs-div-container {
    padding: 0px 120px;
}
.blogs-div-container-heading
{
    padding: 20px 0px;
}
.blogs-div-container-content
{
    padding: 10px 0px;
}
.blogs-div-container-content-main
{
    display: grid;
    grid-template-columns: 32% 32% 32%;
    gap: 1.2rem;
    justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/pages/UI/Blogs/Blogs.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,iBAAiB;AACrB;AACA;IACI,kBAAkB;AACtB;AACA;;IAEI,iBAAiB;AACrB;AACA;;IAEI,iBAAiB;AACrB;AACA;;IAEI,aAAa;IACb,kCAAkC;IAClC,WAAW;IACX,8BAA8B;AAClC","sourcesContent":[".blogs-div {\r\n    font-family: 'Open Sans';\r\n    padding: 20px 0px;\r\n}\r\n.blogs-div-container {\r\n    padding: 0px 120px;\r\n}\r\n.blogs-div-container-heading\r\n{\r\n    padding: 20px 0px;\r\n}\r\n.blogs-div-container-content\r\n{\r\n    padding: 10px 0px;\r\n}\r\n.blogs-div-container-content-main\r\n{\r\n    display: grid;\r\n    grid-template-columns: 32% 32% 32%;\r\n    gap: 1.2rem;\r\n    justify-content: space-between;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
