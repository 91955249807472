// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wishlist-div {
  width: 100%;
  font-family: "Open Sans";
  padding: 30px 0px;
}

.wishlist-div-container {
  padding: 0;
}

.wishlis-remove-wishList {
  background-color: var(--Logo-Color6);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wishlist-circle-icon {
  display: flex;
  clip-path: circle();
  align-items: center;
  justify-content: center;
}

.wishlis-remove-wishList-2 {
  background-color: var(--Logo-Color1);
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/pages/UI/WishList/WishList.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,wBAAwB;EACxB,iBAAiB;AACnB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,oCAAoC;EACpC,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,oCAAoC;EACpC,YAAY;AACd","sourcesContent":[".wishlist-div {\r\n  width: 100%;\r\n  font-family: \"Open Sans\";\r\n  padding: 30px 0px;\r\n}\r\n\r\n.wishlist-div-container {\r\n  padding: 0;\r\n}\r\n\r\n.wishlis-remove-wishList {\r\n  background-color: var(--Logo-Color6);\r\n  color: white;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n\r\n.wishlist-circle-icon {\r\n  display: flex;\r\n  clip-path: circle();\r\n  align-items: center;\r\n  justify-content: center;\r\n}\r\n\r\n.wishlis-remove-wishList-2 {\r\n  background-color: var(--Logo-Color1);\r\n  color: white;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
