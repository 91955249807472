// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* In your CSS file */
.bottom-to-top-button {
    display: none;
    position: fixed;
    bottom: 10px;
    right: 0px;
    border: none;
    border-radius: 50%;
    padding: 10px 20px;
    cursor: pointer;
    width: 75px;
    font-size: 16px;
    transition: opacity 0.3s ease-in-out;
    animation-name: fallFromTop;
    animation-duration: 0.8s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
    display: none;
}

@keyframes fallFromTop {
    0% {
        transform: translateY(-500%);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.bottom-to-top-button.visible {
    display: block;
}

.bottom-to-top-button:hover {
    opacity: 0.8;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/BottomToTopButton/BottomToTopButton.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;IACI,aAAa;IACb,eAAe;IACf,YAAY;IACZ,UAAU;IACV,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,WAAW;IACX,eAAe;IACf,oCAAoC;IACpC,2BAA2B;IAC3B,wBAAwB;IACxB,sCAAsC;IACtC,yBAAyB;IACzB,aAAa;AACjB;;AAEA;IACI;QACI,4BAA4B;QAC5B,UAAU;IACd;;IAEA;QACI,wBAAwB;QACxB,UAAU;IACd;AACJ;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,YAAY;AAChB","sourcesContent":["/* In your CSS file */\r\n.bottom-to-top-button {\r\n    display: none;\r\n    position: fixed;\r\n    bottom: 10px;\r\n    right: 0px;\r\n    border: none;\r\n    border-radius: 50%;\r\n    padding: 10px 20px;\r\n    cursor: pointer;\r\n    width: 75px;\r\n    font-size: 16px;\r\n    transition: opacity 0.3s ease-in-out;\r\n    animation-name: fallFromTop;\r\n    animation-duration: 0.8s;\r\n    animation-timing-function: ease-in-out;\r\n    animation-fill-mode: both;\r\n    display: none;\r\n}\r\n\r\n@keyframes fallFromTop {\r\n    0% {\r\n        transform: translateY(-500%);\r\n        opacity: 0;\r\n    }\r\n\r\n    100% {\r\n        transform: translateY(0);\r\n        opacity: 1;\r\n    }\r\n}\r\n\r\n.bottom-to-top-button.visible {\r\n    display: block;\r\n}\r\n\r\n.bottom-to-top-button:hover {\r\n    opacity: 0.8;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
