// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.registration-div {
  font-family: "Open Sans";
  padding: 0px 130px;
}

.registration-div-container-heading {
  padding: 25px 0px;
}

.registration-div-container-content {
  padding: 25px 0px;
}

.registration-div-container-content-form-individual {
  display: grid;
  grid-template-columns: 49% 49%;
  justify-content: space-between;
  margin: 15px 0px;
}

.registration-div-container-content-form-individual .mantine-1fzet7j {
  margin-bottom: 5px;
  font-family: "Open Sans";
}

.registration-div-container-content-form-individual .mantine-gszoqu,
.registration-div-container-content-form-individual .mantine-kcpopt {
  border-radius: 0px;
  height: 2rem;
}

.registration-div-container-content-form-button {
  margin: 15px 0px;
}

.registration-div-container-content-form-button button {
  width: 130px;
  height: 40px;
  border: 0px;
  cursor: pointer;
  background-color: var(--Logo-Color1);
  color: white;
  border-radius: 3px;
}

.go-back-home {
  color: blue;
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Vendor/Registration/Registration.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,8BAA8B;EAC9B,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,wBAAwB;AAC1B;;AAEA;;EAEE,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,WAAW;EACX,eAAe;EACf,oCAAoC;EACpC,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,0BAA0B;AAC5B","sourcesContent":[".registration-div {\r\n  font-family: \"Open Sans\";\r\n  padding: 0px 130px;\r\n}\r\n\r\n.registration-div-container-heading {\r\n  padding: 25px 0px;\r\n}\r\n\r\n.registration-div-container-content {\r\n  padding: 25px 0px;\r\n}\r\n\r\n.registration-div-container-content-form-individual {\r\n  display: grid;\r\n  grid-template-columns: 49% 49%;\r\n  justify-content: space-between;\r\n  margin: 15px 0px;\r\n}\r\n\r\n.registration-div-container-content-form-individual .mantine-1fzet7j {\r\n  margin-bottom: 5px;\r\n  font-family: \"Open Sans\";\r\n}\r\n\r\n.registration-div-container-content-form-individual .mantine-gszoqu,\r\n.registration-div-container-content-form-individual .mantine-kcpopt {\r\n  border-radius: 0px;\r\n  height: 2rem;\r\n}\r\n\r\n.registration-div-container-content-form-button {\r\n  margin: 15px 0px;\r\n}\r\n\r\n.registration-div-container-content-form-button button {\r\n  width: 130px;\r\n  height: 40px;\r\n  border: 0px;\r\n  cursor: pointer;\r\n  background-color: var(--Logo-Color1);\r\n  color: white;\r\n  border-radius: 3px;\r\n}\r\n\r\n.go-back-home {\r\n  color: blue;\r\n  text-decoration: underline;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
