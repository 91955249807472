// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.copyright-div {
    width: 100%;
    height: 60px;
    background-color: var(--Logo-Color3);
    color: white;
    font-family: 'Open Sans';
}

.copyright-div-container {
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.copyright-div-container-copyright p
{
    font-size: 14px;
}
.copyright-div-container-cards
{
    display: flex;
    gap: 0.3rem;
}`, "",{"version":3,"sources":["webpack://./src/layouts/footer/Copyright/Copyright.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,YAAY;IACZ,wBAAwB;AAC5B;;AAEA;IACI,UAAU;IACV,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;AACA;;IAEI,eAAe;AACnB;AACA;;IAEI,aAAa;IACb,WAAW;AACf","sourcesContent":[".copyright-div {\r\n    width: 100%;\r\n    height: 60px;\r\n    background-color: var(--Logo-Color3);\r\n    color: white;\r\n    font-family: 'Open Sans';\r\n}\r\n\r\n.copyright-div-container {\r\n    padding: 0;\r\n    width: 100%;\r\n    height: 100%;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: space-between;\r\n}\r\n.copyright-div-container-copyright p\r\n{\r\n    font-size: 14px;\r\n}\r\n.copyright-div-container-cards\r\n{\r\n    display: flex;\r\n    gap: 0.3rem;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
