// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sub-div-carosel {
    position: absolute;
    width: 100%;
    margin: auto;
    bottom: 35px;
    z-index: 1;
}

.sub-div-carosel-main {
    width: 92%;
    margin: auto;
    height: 180px;
    display: grid;
    grid-template-columns: 19% 19% 19% 19% 19%;
    justify-content: space-between;
}

.sub-div-carosel-main-grid {
    background-color: #fff;
    padding: 14px;
    border-radius: 8px;
    width: 100%;
}

.sub-div-carosel-main-grid-heading p {
    font-size: 21px;
    font-weight: bold;
    text-align: center;
}

.sub-div-carosel-main-grid-content {
    width: 100%;
    height: 100px;
    display: grid;
    grid-template-columns: 30% 64%;
    align-items: center;
    padding: 14px 0;
    justify-content: space-between;
}

.sub-div-carosel-main-grid-content-left {
    width: 97px;
}

.sub-div-carosel-main-grid-content-left img {
    width: 72px;
    height: 70px;
}

.sub-div-carosel-main-grid-content-right {
    height: 100%;
}

.sub-div-carosel-main-grid-content-right p {
    font-size: 13px;
}

.sub-div-carosel-main-grid-content .know-more-link {
    font-size: 13px;
    color: var(--Logo-Color2);
}`, "",{"version":3,"sources":["webpack://./src/components/UI/HomeSlider/SubHomeSlider/SubHomeSlider.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,UAAU;AACd;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,aAAa;IACb,aAAa;IACb,0CAA0C;IAC1C,8BAA8B;AAClC;;AAEA;IACI,sBAAsB;IACtB,aAAa;IACb,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,eAAe;IACf,8BAA8B;AAClC;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,yBAAyB;AAC7B","sourcesContent":[".sub-div-carosel {\r\n    position: absolute;\r\n    width: 100%;\r\n    margin: auto;\r\n    bottom: 35px;\r\n    z-index: 1;\r\n}\r\n\r\n.sub-div-carosel-main {\r\n    width: 92%;\r\n    margin: auto;\r\n    height: 180px;\r\n    display: grid;\r\n    grid-template-columns: 19% 19% 19% 19% 19%;\r\n    justify-content: space-between;\r\n}\r\n\r\n.sub-div-carosel-main-grid {\r\n    background-color: #fff;\r\n    padding: 14px;\r\n    border-radius: 8px;\r\n    width: 100%;\r\n}\r\n\r\n.sub-div-carosel-main-grid-heading p {\r\n    font-size: 21px;\r\n    font-weight: bold;\r\n    text-align: center;\r\n}\r\n\r\n.sub-div-carosel-main-grid-content {\r\n    width: 100%;\r\n    height: 100px;\r\n    display: grid;\r\n    grid-template-columns: 30% 64%;\r\n    align-items: center;\r\n    padding: 14px 0;\r\n    justify-content: space-between;\r\n}\r\n\r\n.sub-div-carosel-main-grid-content-left {\r\n    width: 97px;\r\n}\r\n\r\n.sub-div-carosel-main-grid-content-left img {\r\n    width: 72px;\r\n    height: 70px;\r\n}\r\n\r\n.sub-div-carosel-main-grid-content-right {\r\n    height: 100%;\r\n}\r\n\r\n.sub-div-carosel-main-grid-content-right p {\r\n    font-size: 13px;\r\n}\r\n\r\n.sub-div-carosel-main-grid-content .know-more-link {\r\n    font-size: 13px;\r\n    color: var(--Logo-Color2);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
