// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mantine-eby8v3 {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 14px;
}

.expiredproducts-div {
    font-family: 'Source Sans Pro', sans-serif;
}

.expiredproducts-filter-container {
    display: grid;
    grid-template-columns: 9% 10% 10% 10% auto;
    gap: 1rem;
    font-family: 'Source Sans Pro', sans-serif;
}

.expiredproducts-filter-container-div4 {
    width: 100%;
}

.expiredproducts-filter-container-div4 .mantine-ydg8wd {
    margin: 0px;
    max-width: 250px;
}

.expiredproducts-filter-container-div4 .mantine-1fzet7j,
.expiredproducts-filter-container-div5 .mantine-1fzet7j,
.expiredproducts-filter-container-div3 .mantine-1fzet7j,
.expiredproducts-filter-container-div2 .mantine-1fzet7j,
.expiredproducts-filter-container-div1 .mantine-1fzet7j {
    font-family: 'Source Sans Pro', sans-serif;
    margin-bottom: 5px;
    font-weight: 600 !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/Admin/Product/ExpiredProducts/ExpiredProducts.css"],"names":[],"mappings":"AAAA;IACI,0CAA0C;IAC1C,eAAe;AACnB;;AAEA;IACI,0CAA0C;AAC9C;;AAEA;IACI,aAAa;IACb,0CAA0C;IAC1C,SAAS;IACT,0CAA0C;AAC9C;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;IACX,gBAAgB;AACpB;;AAEA;;;;;IAKI,0CAA0C;IAC1C,kBAAkB;IAClB,2BAA2B;AAC/B","sourcesContent":[".mantine-eby8v3 {\r\n    font-family: 'Source Sans Pro', sans-serif;\r\n    font-size: 14px;\r\n}\r\n\r\n.expiredproducts-div {\r\n    font-family: 'Source Sans Pro', sans-serif;\r\n}\r\n\r\n.expiredproducts-filter-container {\r\n    display: grid;\r\n    grid-template-columns: 9% 10% 10% 10% auto;\r\n    gap: 1rem;\r\n    font-family: 'Source Sans Pro', sans-serif;\r\n}\r\n\r\n.expiredproducts-filter-container-div4 {\r\n    width: 100%;\r\n}\r\n\r\n.expiredproducts-filter-container-div4 .mantine-ydg8wd {\r\n    margin: 0px;\r\n    max-width: 250px;\r\n}\r\n\r\n.expiredproducts-filter-container-div4 .mantine-1fzet7j,\r\n.expiredproducts-filter-container-div5 .mantine-1fzet7j,\r\n.expiredproducts-filter-container-div3 .mantine-1fzet7j,\r\n.expiredproducts-filter-container-div2 .mantine-1fzet7j,\r\n.expiredproducts-filter-container-div1 .mantine-1fzet7j {\r\n    font-family: 'Source Sans Pro', sans-serif;\r\n    margin-bottom: 5px;\r\n    font-weight: 600 !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
