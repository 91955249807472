// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-password-input {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
}

.custom-password-input input {
    width: 100%;
    padding: 10px 20px;
    height: 46px;
    border: 0.0625rem solid #ced4da;
}
.custom-password-input input::placeholder
{
    color: #cac4c2;
}
.custom-password-input input:focus
{
    border: 0.0625rem solid #52a4eb;
    outline: #52a4eb;
}

.custom-password-input img {
    width: 16px;
    position: absolute;
    right: 8px;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/Admin/CustomPasswordInput/CustomPasswordInput.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,YAAY;IACZ,+BAA+B;AACnC;AACA;;IAEI,cAAc;AAClB;AACA;;IAEI,+BAA+B;IAC/B,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,UAAU;IACV,eAAe;AACnB","sourcesContent":[".custom-password-input {\r\n    width: 100%;\r\n    display: flex;\r\n    align-items: center;\r\n    position: relative;\r\n}\r\n\r\n.custom-password-input input {\r\n    width: 100%;\r\n    padding: 10px 20px;\r\n    height: 46px;\r\n    border: 0.0625rem solid #ced4da;\r\n}\r\n.custom-password-input input::placeholder\r\n{\r\n    color: #cac4c2;\r\n}\r\n.custom-password-input input:focus\r\n{\r\n    border: 0.0625rem solid #52a4eb;\r\n    outline: #52a4eb;\r\n}\r\n\r\n.custom-password-input img {\r\n    width: 16px;\r\n    position: absolute;\r\n    right: 8px;\r\n    cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
