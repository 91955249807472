// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-orders-div {
    width: 100%;
    padding: 30px 0px;
    font-family: 'Open Sans';
}

.user-orders-div-container {
    padding: 0px 120px;
}

.user-orders-div-container-heading {
    padding: 20px 0px;
}

.user-orders-div-container-content {
    padding: 20px 0px;
}
.user-orders-button-style
{
    background-color: var(--Logo-Color2);
    color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/pages/UI/UserOrders/UserOrders.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;IACjB,wBAAwB;AAC5B;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;AACA;;IAEI,oCAAoC;IACpC,WAAW;AACf","sourcesContent":[".user-orders-div {\r\n    width: 100%;\r\n    padding: 30px 0px;\r\n    font-family: 'Open Sans';\r\n}\r\n\r\n.user-orders-div-container {\r\n    padding: 0px 120px;\r\n}\r\n\r\n.user-orders-div-container-heading {\r\n    padding: 20px 0px;\r\n}\r\n\r\n.user-orders-div-container-content {\r\n    padding: 20px 0px;\r\n}\r\n.user-orders-button-style\r\n{\r\n    background-color: var(--Logo-Color2);\r\n    color: #fff;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
