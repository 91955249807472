// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.transactions-div {
    font-family: 'Source Sans Pro', sans-serif;
}

.transactions-filter-container {
    display: grid;
    grid-template-columns: 9% 20% 20% auto;
    gap: 1rem;
    font-family: 'Source Sans Pro', sans-serif;
    align-items: flex-end;
}

.transactions-filter-container-div4 .mantine-1fzet7j,
.transactions-filter-container-div2 .mantine-1fzet7j,
.transactions-filter-container-div1 .mantine-1fzet7j {
    font-family: 'Source Sans Pro', sans-serif;
    margin-bottom: 5px;
    font-weight: 600 !important;
}
/* .transactions-filter-container-div2
{
    display: flex;
} */

.transactions-filter-container-div2 .mantine-1fzet7j {
    display: block !important;
}

.transactions-filter-container-div2 .mantine-ydg8wd {
    margin: 0px;
    display: flex;
    flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/pages/Admin/Orders/Transactions/Transactions.css"],"names":[],"mappings":"AAAA;IACI,0CAA0C;AAC9C;;AAEA;IACI,aAAa;IACb,sCAAsC;IACtC,SAAS;IACT,0CAA0C;IAC1C,qBAAqB;AACzB;;AAEA;;;IAGI,0CAA0C;IAC1C,kBAAkB;IAClB,2BAA2B;AAC/B;AACA;;;GAGG;;AAEH;IACI,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;AAC1B","sourcesContent":[".transactions-div {\r\n    font-family: 'Source Sans Pro', sans-serif;\r\n}\r\n\r\n.transactions-filter-container {\r\n    display: grid;\r\n    grid-template-columns: 9% 20% 20% auto;\r\n    gap: 1rem;\r\n    font-family: 'Source Sans Pro', sans-serif;\r\n    align-items: flex-end;\r\n}\r\n\r\n.transactions-filter-container-div4 .mantine-1fzet7j,\r\n.transactions-filter-container-div2 .mantine-1fzet7j,\r\n.transactions-filter-container-div1 .mantine-1fzet7j {\r\n    font-family: 'Source Sans Pro', sans-serif;\r\n    margin-bottom: 5px;\r\n    font-weight: 600 !important;\r\n}\r\n/* .transactions-filter-container-div2\r\n{\r\n    display: flex;\r\n} */\r\n\r\n.transactions-filter-container-div2 .mantine-1fzet7j {\r\n    display: block !important;\r\n}\r\n\r\n.transactions-filter-container-div2 .mantine-ydg8wd {\r\n    margin: 0px;\r\n    display: flex;\r\n    flex-direction: column;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
