// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-comments-div {
    font-family: 'Source Sans Pro', sans-serif;
}

.product-comments-div h3 {
    padding: 20px 0px;
}

.product-comments-div-card {
    padding: 0px;
}

.product-comments-div-card-heading {
    padding: 20px 20px 5px;
    display: flex;
    justify-content: space-between;
}

.product-comments-div-card-heading h4 {
    font-weight: 400;
}

.product-comments-div-card-heading button {
    width: 171px;
    height: 34px;
    display: flex;
    justify-content: space-evenly;
    cursor: pointer;
    border-radius: 3px;
    border: 0px;
    background-color: var(--Logo-Color1);
    color: #fff;
    align-items: center;
}

.product-comments-div-card-content {
    padding: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Admin/Comments/BlogComments/BlogComments.css"],"names":[],"mappings":"AAAA;IACI,0CAA0C;AAC9C;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,sBAAsB;IACtB,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,6BAA6B;IAC7B,eAAe;IACf,kBAAkB;IAClB,WAAW;IACX,oCAAoC;IACpC,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".product-comments-div {\r\n    font-family: 'Source Sans Pro', sans-serif;\r\n}\r\n\r\n.product-comments-div h3 {\r\n    padding: 20px 0px;\r\n}\r\n\r\n.product-comments-div-card {\r\n    padding: 0px;\r\n}\r\n\r\n.product-comments-div-card-heading {\r\n    padding: 20px 20px 5px;\r\n    display: flex;\r\n    justify-content: space-between;\r\n}\r\n\r\n.product-comments-div-card-heading h4 {\r\n    font-weight: 400;\r\n}\r\n\r\n.product-comments-div-card-heading button {\r\n    width: 171px;\r\n    height: 34px;\r\n    display: flex;\r\n    justify-content: space-evenly;\r\n    cursor: pointer;\r\n    border-radius: 3px;\r\n    border: 0px;\r\n    background-color: var(--Logo-Color1);\r\n    color: #fff;\r\n    align-items: center;\r\n}\r\n\r\n.product-comments-div-card-content {\r\n    padding: 20px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
