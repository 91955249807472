import React from "react";
import { Container } from "@mantine/core";
import Breadcrumb from "../../../components/UI/Breadcrumb/Breadcrumb";
import "./Quote.css";
const Quote = () => {
  // array for breadcrumb
  const items = [
    { title: "Home", href: "/" },
    { title: "User Quote Requests", href: "/ui_quote" },
  ];

  return (
    <div className="quote-div">
      <Container className="quote-div-container" size="82rem">
        <div className="category-div-container-breadcrumb">
          <Breadcrumb value={items} />
        </div>
        <div className="quote-div-container-heading">
          <h2>Quote Requests</h2>
        </div>
        <div className="quote-div-container-content">
          <table className="quote-div-container-content-table">
            <thead>
              <tr>
                <th>Quote</th>
                <th>Product Name</th>
                <th>Seller</th>
                <th>Status</th>
                <th>Seller's Bid</th>
                <th>Updated</th>
                <th>Options</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>#1</td>
                <td>Cobalt men t-shirt all colors (Color: Teal) Quantity: 6</td>
                <td>Mogo</td>
                <td>Pending Quote</td>
                <td>1000</td>
                <td>Just Now</td>
                <td>
                  <div className="options-quote-div">
                    <button className="quote-accept">Accept Quote</button>
                    <button className="quote-reject">Reject Quote</button>
                    <button className="quote-delete">Delete Quote</button>
                  </div>
                </td>
              </tr>
              <tr>
                <td>#2</td>
                <td>Cobalt men t-shirt all colors (Color: Teal) Quantity: 6</td>
                <td>Mogo</td>
                <td>New Quote Request</td>
                <td></td>
                <td>Just Now</td>
                <td>
                  <div className="options-quote-div">
                    <button className="quote-delete">Delete Quote</button>
                  </div>
                </td>
              </tr>
              <tr>
                <td>#3</td>
                <td>Cobalt men t-shirt all colors (Color: Teal) Quantity: 6</td>
                <td>Mogo</td>
                <td>Completed</td>
                <td>1000</td>
                <td>Just Now</td>
                <td>
                  <div className="options-quote-div">
                    <button className="quote-delete">Delete Quote</button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Container>
    </div>
  );
};

export default Quote;
