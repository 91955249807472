// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-orders-individual-div {
    width: 100%;
    padding: 30px 0px;
    font-family: 'Open Sans';
}

.user-orders-individual-div-container {
    padding: 0px 120px;
}

.user-orders-individual-div-container-heading {
    padding: 20px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.user-Table-td-style-image img
{
    width: 100px;
    height: 100px;
}
.LatestProducts-Table-td-style
{
    width: 300px;
}

.user-orders-individual-div-container-content {
    padding: 20px 0px;
}
.user-rating-div
{
    display: flex;
    gap: 1rem;
    font-size: 16px;
    font-family: 'Open Sans';
    align-items: center;
    font-weight: 600;
}
.user-review-model-button
{
    gap: 1rem;
}
.user-review-model-button .user-review-model-button-close
{
    background-color: var(--Discount-Color) !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/UI/UserOrdersIndividual/UserOrdersIndividual.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;IACjB,wBAAwB;AAC5B;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;AACA;;IAEI,YAAY;IACZ,aAAa;AACjB;AACA;;IAEI,YAAY;AAChB;;AAEA;IACI,iBAAiB;AACrB;AACA;;IAEI,aAAa;IACb,SAAS;IACT,eAAe;IACf,wBAAwB;IACxB,mBAAmB;IACnB,gBAAgB;AACpB;AACA;;IAEI,SAAS;AACb;AACA;;IAEI,kDAAkD;AACtD","sourcesContent":[".user-orders-individual-div {\r\n    width: 100%;\r\n    padding: 30px 0px;\r\n    font-family: 'Open Sans';\r\n}\r\n\r\n.user-orders-individual-div-container {\r\n    padding: 0px 120px;\r\n}\r\n\r\n.user-orders-individual-div-container-heading {\r\n    padding: 20px 0px;\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n}\r\n.user-Table-td-style-image img\r\n{\r\n    width: 100px;\r\n    height: 100px;\r\n}\r\n.LatestProducts-Table-td-style\r\n{\r\n    width: 300px;\r\n}\r\n\r\n.user-orders-individual-div-container-content {\r\n    padding: 20px 0px;\r\n}\r\n.user-rating-div\r\n{\r\n    display: flex;\r\n    gap: 1rem;\r\n    font-size: 16px;\r\n    font-family: 'Open Sans';\r\n    align-items: center;\r\n    font-weight: 600;\r\n}\r\n.user-review-model-button\r\n{\r\n    gap: 1rem;\r\n}\r\n.user-review-model-button .user-review-model-button-close\r\n{\r\n    background-color: var(--Discount-Color) !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
