// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-div {
    font-family: 'Open Sans';
    padding: 30px 0px;
}

.about-div-container {
    padding: 0px;
}

.about-div-container-main-head h1 {
    font-size: 26px;
    margin: 30px 0px;
}

.about-div-container-main-content p {
    font-size: 15px;
    margin-bottom: 16px;
}`, "",{"version":3,"sources":["webpack://./src/pages/UI/About/About.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,iBAAiB;AACrB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB","sourcesContent":[".about-div {\r\n    font-family: 'Open Sans';\r\n    padding: 30px 0px;\r\n}\r\n\r\n.about-div-container {\r\n    padding: 0px;\r\n}\r\n\r\n.about-div-container-main-head h1 {\r\n    font-size: 26px;\r\n    margin: 30px 0px;\r\n}\r\n\r\n.about-div-container-main-content p {\r\n    font-size: 15px;\r\n    margin-bottom: 16px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
