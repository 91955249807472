// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breadcrumb {
    color: var(--Logo-Color1);
    font-family: 'Open Sans';
    font-size: 13px;
}

.breadcrumb-link {
    text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/Breadcrumb/Breadcrumb.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,wBAAwB;IACxB,eAAe;AACnB;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".breadcrumb {\r\n    color: var(--Logo-Color1);\r\n    font-family: 'Open Sans';\r\n    font-size: 13px;\r\n}\r\n\r\n.breadcrumb-link {\r\n    text-decoration: none;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
