// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-shipping-method-div {
  padding-top: 10px;
}
.add-shipping-zone-save-btn {
  display: flex;
  justify-content: space-evenly;
  width: 60px;
  height: 34px;
  background-color: var(--Logo-Color1);
  color: #fff;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
}

.sellerbalance-edit-modal {
  padding: 20px !important;
}

.add-shipping-method-main-div {
  padding: 20px 0px;
}

.add-shipping-method-header h4 {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  font-size: 14px;
}

.add-shipping-zone-btn {
  margin-top: 20px;
  width: 200px !important;
}

.shipping-zone-display-table td {
  text-align: -webkit-center !important;
}


.switchDiv {
  display: flex !important;
  justify-content: center !important;
  align-content: center !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Admin/ShippingSettings/Add Shipping Zone/AddShippingZone.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,6BAA6B;EAC7B,WAAW;EACX,YAAY;EACZ,oCAAoC;EACpC,WAAW;EACX,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,0CAA0C;EAC1C,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,qCAAqC;AACvC;;;AAGA;EACE,wBAAwB;EACxB,kCAAkC;EAClC,gCAAgC;AAClC","sourcesContent":[".add-shipping-method-div {\r\n  padding-top: 10px;\r\n}\r\n.add-shipping-zone-save-btn {\r\n  display: flex;\r\n  justify-content: space-evenly;\r\n  width: 60px;\r\n  height: 34px;\r\n  background-color: var(--Logo-Color1);\r\n  color: #fff;\r\n  align-items: center;\r\n  border-radius: 3px;\r\n  cursor: pointer;\r\n}\r\n\r\n.sellerbalance-edit-modal {\r\n  padding: 20px !important;\r\n}\r\n\r\n.add-shipping-method-main-div {\r\n  padding: 20px 0px;\r\n}\r\n\r\n.add-shipping-method-header h4 {\r\n  font-family: \"Source Sans Pro\", sans-serif;\r\n  font-weight: 600;\r\n  font-size: 14px;\r\n}\r\n\r\n.add-shipping-zone-btn {\r\n  margin-top: 20px;\r\n  width: 200px !important;\r\n}\r\n\r\n.shipping-zone-display-table td {\r\n  text-align: -webkit-center !important;\r\n}\r\n\r\n\r\n.switchDiv {\r\n  display: flex !important;\r\n  justify-content: center !important;\r\n  align-content: center !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
