// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.earnings-div {
    font-family: 'Source Sans Pro', sans-serif;
}

.earnings-filter-container {
    display: grid;
    grid-template-columns: 9% 20% 20% auto;
    gap: 1rem;
    font-family: 'Source Sans Pro', sans-serif;
    align-items: baseline;
}

.earnings-filter-container-div4 .mantine-1fzet7j,
.earnings-filter-container-div1 .mantine-1fzet7j {
    font-family: 'Source Sans Pro', sans-serif;
    margin-bottom: 5px;
    font-weight: 600 !important;
}

.earnings-count {
    padding: 20px;
    margin-bottom: 1.5rem;
    width: 25%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.earnings-count-left p {
    color: var(--Para-Color);
}
.earnings-count-right
{
    font-size: 45px;
    color: rgb(220, 220, 220);
}`, "",{"version":3,"sources":["webpack://./src/pages/Vendor/Earnings/Earnings.css"],"names":[],"mappings":"AAAA;IACI,0CAA0C;AAC9C;;AAEA;IACI,aAAa;IACb,sCAAsC;IACtC,SAAS;IACT,0CAA0C;IAC1C,qBAAqB;AACzB;;AAEA;;IAEI,0CAA0C;IAC1C,kBAAkB;IAClB,2BAA2B;AAC/B;;AAEA;IACI,aAAa;IACb,qBAAqB;IACrB,UAAU;IACV,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,wBAAwB;AAC5B;AACA;;IAEI,eAAe;IACf,yBAAyB;AAC7B","sourcesContent":[".earnings-div {\r\n    font-family: 'Source Sans Pro', sans-serif;\r\n}\r\n\r\n.earnings-filter-container {\r\n    display: grid;\r\n    grid-template-columns: 9% 20% 20% auto;\r\n    gap: 1rem;\r\n    font-family: 'Source Sans Pro', sans-serif;\r\n    align-items: baseline;\r\n}\r\n\r\n.earnings-filter-container-div4 .mantine-1fzet7j,\r\n.earnings-filter-container-div1 .mantine-1fzet7j {\r\n    font-family: 'Source Sans Pro', sans-serif;\r\n    margin-bottom: 5px;\r\n    font-weight: 600 !important;\r\n}\r\n\r\n.earnings-count {\r\n    padding: 20px;\r\n    margin-bottom: 1.5rem;\r\n    width: 25%;\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n}\r\n\r\n.earnings-count-left p {\r\n    color: var(--Para-Color);\r\n}\r\n.earnings-count-right\r\n{\r\n    font-size: 45px;\r\n    color: rgb(220, 220, 220);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
