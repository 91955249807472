// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.numeric-input-form {
    display: flex;
    flex-direction: column;
}
.numeric-input-form .numeric-input {
    width: 100%;
    border: 1px solid var(--Extra-Light-Gray);
    height: 2rem;
    padding-left: 0.3rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Admin/Input/NumericInput.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;AACA;IACI,WAAW;IACX,yCAAyC;IACzC,YAAY;IACZ,oBAAoB;AACxB","sourcesContent":[".numeric-input-form {\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n.numeric-input-form .numeric-input {\r\n    width: 100%;\r\n    border: 1px solid var(--Extra-Light-Gray);\r\n    height: 2rem;\r\n    padding-left: 0.3rem;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
