// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cancelledsales-div {
    font-family: 'Source Sans Pro', sans-serif;
}

.activesales-div-card {
    padding: 0px;
}

.activesales-div-card-heading {
    padding: 20px 20px 5px;
}

.activesales-div-card-content {
    padding: 20px;
}

.sales-button {
    cursor: pointer;
    width: 80px;
    height: 32px;
    border: 0px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.cancelled-filter-container {
    display: grid;
    grid-template-columns: 9% 20% 20%;
    gap: 1rem;
    font-family: 'Source Sans Pro', sans-serif;
    align-items: baseline;
}`, "",{"version":3,"sources":["webpack://./src/pages/Vendor/Sales/CancelledSales/CancelledSales.css"],"names":[],"mappings":"AAAA;IACI,0CAA0C;AAC9C;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,WAAW;IACX,YAAY;IACZ,WAAW;IACX,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,iCAAiC;IACjC,SAAS;IACT,0CAA0C;IAC1C,qBAAqB;AACzB","sourcesContent":[".cancelledsales-div {\r\n    font-family: 'Source Sans Pro', sans-serif;\r\n}\r\n\r\n.activesales-div-card {\r\n    padding: 0px;\r\n}\r\n\r\n.activesales-div-card-heading {\r\n    padding: 20px 20px 5px;\r\n}\r\n\r\n.activesales-div-card-content {\r\n    padding: 20px;\r\n}\r\n\r\n.sales-button {\r\n    cursor: pointer;\r\n    width: 80px;\r\n    height: 32px;\r\n    border: 0px;\r\n    display: flex;\r\n    justify-content: space-evenly;\r\n    align-items: center;\r\n}\r\n.cancelled-filter-container {\r\n    display: grid;\r\n    grid-template-columns: 9% 20% 20%;\r\n    gap: 1rem;\r\n    font-family: 'Source Sans Pro', sans-serif;\r\n    align-items: baseline;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
